import { Item } from '@/types/Item';
import { ItemDetail } from '@/types/ItemDetail';
import { BlobConstants } from './BlobConstants';

export class BlobStorageReader {

	public static readonly Instance: BlobStorageReader = new BlobStorageReader();

	public async getGalleryData() : Promise<Item[]> {
		const path = `/${BlobConstants.BLOB_CONTAINER}/${BlobConstants.GALLERY_BLOB}`;
		const response = await fetch(`${BlobConstants.BLOB_STORAGE_HOST}${path}`, {
			method: 'GET',
		});
		if (response.ok) {
			const itemList: Item[] = JSON.parse(await response.text());
			return itemList;
		}
		else {
			return [];
		}
	}

	public async getPropertyDetailData(id: string) : Promise<ItemDetail | undefined> {
		const path = `/${BlobConstants.BLOB_CONTAINER}/${id}.json`;
		const response = await fetch(`${BlobConstants.BLOB_STORAGE_HOST}${path}`, {
			method: 'GET',
		});

		if (response.ok) {
			const item: ItemDetail = JSON.parse(await response.text());
			return item;
		}
		else {
			return undefined;
		}
	}
}