<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/contact">Contact</router-link>
  </nav>
  <router-view style="background-color: rgb(249, 249, 249);"/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
  margin-top: 3.188vw;
  margin-left: 2.875vw;
  text-align: center;
}

nav a {
  font-weight: 700;
  color: #121275;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0 0.375vw;
  text-decoration: none;
}
</style>
