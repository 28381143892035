<template>
    <div class="item-hd-image-root">
        <div class="item-hd-image-container" @mouseenter="mouseOnHdImage=true;" @mouseleave="mouseOnHdImage=false">
            <div class="item-hd-image-with-btn">
                <img :src="imageListWithIndex[selectedThumbnail].url" :alt="imageListWithIndex[selectedThumbnail].url" class="item-hd-image-current" />
                <CarouselButton v-if="mouseOnHdImage" class="item-hd-image-left-button" @click="onLeftCarouselButtonClick" />
                <CarouselButton v-if="mouseOnHdImage" class="item-hd-image-right-button" :isRight="true" @click="onRightCarouselButtonClick" />
            </div>
        </div>
        <div class="item-hd-image-thumbnail-list-container">
            <div class="item-hd-image-thumbnail-container" v-for="image in imageListWithIndex" :key="image.index" >
                <img :src="image.url" :alt="image.url" class="item-hd-image-thumbnail-unselected" :class="{ item_hd_image_thumbnail_selected : image.index === selectedThumbnail }" @mouseover="onThumbnailHover(image)" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { BlobConstants } from '@/blobs/BlobConstants';
import { ref } from 'vue';
import CarouselButton from './CarouselButton.vue';

const props = defineProps<{
    imageList: string[]
}>();

type ImageWithIndex = {url:string} & {index:number}

const selectedThumbnail = ref(0);

const imageListWithIndex: ImageWithIndex[] = [];
for (let index = 0; index < props.imageList.length; index++) {
	imageListWithIndex.push({ index: index, url: BlobConstants.BLOB_STORAGE_HOST + props.imageList[index] });
}

const onThumbnailHover = (imageWithIndex: ImageWithIndex) => {
	selectedThumbnail.value = imageWithIndex.index;
};

const mouseOnHdImage = ref(false);
const onLeftCarouselButtonClick = () => {
	if (selectedThumbnail.value > 0) {
		selectedThumbnail.value--;
	}
};
const onRightCarouselButtonClick = () => {
	if (selectedThumbnail.value < imageListWithIndex.length - 1) {
		selectedThumbnail.value++;
	}
};
</script>


<style>

.item-hd-image-current {
    width: 100%;
    height: 100%;
    object-fit:cover;
}

.item-hd-image-thumbnail-list-container {
    overflow: auto;
    max-width: 90vw;
    margin: 10px 0px;
    display: flex;
}

.item-hd-image-thumbnail-container {
    margin-right: 10px;
}

.item-hd-image-thumbnail-unselected {
    width: 75px;
    height: 75px;
    object-fit: cover;
    opacity: 0.5;
}

.item_hd_image_thumbnail_selected,img {
    opacity: 1;
}

.item-hd-image-container {
    display: flex;
}
.item-hd-image-with-btn {
    max-height: 1000px;
    max-width: 1000px;
    width: 100%;
    height: auto;
    position: relative;
    display: inline;
}
.item-hd-image-left-button {
    position: absolute;
    top: 45%;
    left: 10px;
}
.item-hd-image-right-button {
    position: absolute;
    top: 45%;
    right: 10px;
}
</style>