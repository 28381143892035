<template>
  <div class="root">
    <h1 class="name">
        <span class="first-name">ELLEN</span>
        <span class="last-name">ZHANG</span>
    </h1>
    <div class="intro-container">
        <span class="side-line"></span>
        <p class="intro">A real estate expert, Ellen Zhang is a zealous advocate for her clients, solving problems to provide a smooth and efficient experience. Having helped buyers and sellers in numerous transactions, Ellen Zhang offers her clients a deep understanding of the myriad circumstances and issues that may arise during the process. she negotiates tenaciously and works endlessly to minimize the client's stress and command results. Because she has lived the United States and experienced extensive world travel, Ellen Zhang understands the importance of looking at markets from a local and global perspective. In addition to an experienced market view, she is constantly searching for up-to-date market data and trends to offer her clients expert insights. she crafts a unique story with each home allowing for a quick and profitable sale for the client. Ellen Zhang aligned with WeLakesdie to offer her clients the first-class service they deserve. This service ranges from the firm’s award-winning marketing and expansive network to its trusted brand, which elevates properties across unparalleled local and global platforms.</p>
    </div>
    <div class="contact-me-container">
      <a class="contact-me-btn" @click="onClick">Contact Ellen</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from '@/router/index';
const onClick = () => {
	router.push({ name: 'contact' });
};
</script>

<style scoped>

.root {
    --mobileMultiplier: 1;
}

@media (max-width: 800px) {
    .root {
        --mobileMultiplier: 2;
    }
}

.first-name {
    display: block;
    font-size: calc(4.8vw * var(--mobileMultiplier));
    font-weight: 400;
    color: #999999;
    text-transform: uppercase;
}
.last-name {
    display: block;
    font-size: calc(6.18vw * var(--mobileMultiplier));
    font-weight: 800;
    color: #111275;
    text-transform: uppercase;
    margin-left: 4.4vw;
}
.intro-container {
    position: relative;
    max-width: calc(36.25vw * var(--mobileMultiplier));
    margin-top: calc(2.875vw * var(--mobileMultiplier));
    padding-left: calc(2.938vw * var(--mobileMultiplier));
}
.side-line {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: calc(0.625vw * var(--mobileMultiplier));
    height: calc(32vw * var(--mobileMultiplier));
    background-color: #121275;
    pointer-events: none;
}
.intro {
    font-size: calc(1vw * var(--mobileMultiplier));
    font-weight: 400;
    color: #333333;
    line-height: 2;
}
.contact-me-container {
    display: block;
    margin-top: 3.625vw;
    padding-left: 2.875vw;
    margin-right: 1.25vw;
    width: calc(12.5vw * var(--mobileMultiplier));
}
.contact-me-btn {
    display: block;
    font-size: calc(0.875vw * var(--mobileMultiplier));
    font-weight: 700;
    color: #121275;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid rgba(0, 1, 1, 0.3);
    padding: calc(1.313vw * var(--mobileMultiplier)) 0;
}
.contact-me-btn:hover {
    color: #fff;
    background-color: #121275;
    border-color: #121275;
    cursor: pointer;
}
</style>