<template>
    <div>
        <p class="price">{{ price }}</p>
        <h1 class="street-name">{{ itemDetail.address.streetName }}</h1>
        <p class="city-state-zip">{{ itemDetail.address.city }}, {{ itemDetail.address.state }}, {{ itemDetail.address.zipcode }}</p>
        <div style="height: 30px;"></div>
        <div class="info-box-container">
            <div class="info-box">
                <svg class="icon" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.83331 11.8334H1.99998L1.45831 10.1667H0.333313V5.97919C0.333313 5.52085 0.496507 5.13197 0.822896 4.81252C1.14929 4.49308 1.54165 4.33335 1.99998 4.33335V1.83335C1.99998 1.37502 2.16317 0.982659 2.48956 0.65627C2.81595 0.329881 3.20831 0.166687 3.66665 0.166687H10.3333C10.7916 0.166687 11.184 0.329881 11.5104 0.65627C11.8368 0.982659 12 1.37502 12 1.83335V4.33335C12.4583 4.33335 12.8507 4.49655 13.1771 4.82294C13.5035 5.14933 13.6666 5.54169 13.6666 6.00002V10.1667H12.5416L12 11.8334H11.1666L10.625 10.1667H3.37498L2.83331 11.8334ZM7.83331 4.33335H10.3333V1.83335H7.83331V4.33335ZM3.66665 4.33335H6.16665V1.83335H3.66665V4.33335ZM1.99998 8.50002H12V6.00002H1.99998V8.50002Z" fill="#707070"/>
                </svg>
                <p class="info-text">
                    <strong class="info-number">{{ itemDetail.beds }}</strong>
                     Beds
                </p>
            </div>
            <div class="info-box">
                <svg class="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.83335 6.50004C4.37502 6.50004 3.98266 6.33684 3.65627 6.01045C3.32988 5.68406 3.16669 5.2917 3.16669 4.83337C3.16669 4.37504 3.32988 3.98267 3.65627 3.65629C3.98266 3.3299 4.37502 3.1667 4.83335 3.1667C5.29169 3.1667 5.68405 3.3299 6.01044 3.65629C6.33683 3.98267 6.50002 4.37504 6.50002 4.83337C6.50002 5.2917 6.33683 5.68406 6.01044 6.01045C5.68405 6.33684 5.29169 6.50004 4.83335 6.50004ZM3.16669 17.3334C2.93058 17.3334 2.73266 17.2535 2.57294 17.0938C2.41321 16.9341 2.33335 16.7361 2.33335 16.5C1.87502 16.5 1.48266 16.3368 1.15627 16.0105C0.829881 15.6841 0.666687 15.2917 0.666687 14.8334V9.83337H3.16669V9.20837C3.16669 8.68059 3.34724 8.23615 3.70835 7.87504C4.06946 7.51392 4.51391 7.33337 5.04169 7.33337C5.31946 7.33337 5.57641 7.38892 5.81252 7.50004C6.04863 7.61115 6.26391 7.76392 6.45835 7.95837L7.62502 9.25004C7.73613 9.36115 7.84377 9.46531 7.94794 9.56254C8.0521 9.65976 8.16669 9.75004 8.29169 9.83337H14V3.0417C14 2.84726 13.9306 2.68059 13.7917 2.5417C13.6528 2.40281 13.4861 2.33337 13.2917 2.33337C13.2084 2.33337 13.1285 2.35073 13.0521 2.38545C12.9757 2.42017 12.9028 2.47226 12.8334 2.5417L11.7917 3.58337C11.8611 3.81948 11.875 4.05212 11.8334 4.28129C11.7917 4.51045 11.7084 4.72226 11.5834 4.9167L9.29169 2.58337C9.48613 2.45837 9.69446 2.37851 9.91669 2.34379C10.1389 2.30906 10.3611 2.33337 10.5834 2.4167L11.625 1.37504C11.8472 1.15281 12.1007 0.979202 12.3854 0.854202C12.6702 0.729202 12.9722 0.666702 13.2917 0.666702C13.9584 0.666702 14.5209 0.895869 14.9792 1.3542C15.4375 1.81254 15.6667 2.37504 15.6667 3.0417V9.83337H17.3334V14.8334C17.3334 15.2917 17.1702 15.6841 16.8438 16.0105C16.5174 16.3368 16.125 16.5 15.6667 16.5C15.6667 16.7361 15.5868 16.9341 15.4271 17.0938C15.2674 17.2535 15.0695 17.3334 14.8334 17.3334H3.16669ZM2.33335 14.8334H15.6667V11.5H2.33335V14.8334Z" fill="#707070"/>
                </svg>
                <p class="info-text">
                    <strong class="info-number">{{ itemDetail.baths }}</strong>
                     Baths
                </p>
            </div>
            <div class="info-box">
                <svg class="icon" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.33335 10C1.87502 10 1.48266 9.83684 1.15627 9.51045C0.829881 9.18406 0.666687 8.7917 0.666687 8.33336V1.6667C0.666687 1.20836 0.829881 0.816003 1.15627 0.489614C1.48266 0.163225 1.87502 3.05176e-05 2.33335 3.05176e-05H15.6667C16.125 3.05176e-05 16.5174 0.163225 16.8438 0.489614C17.1702 0.816003 17.3334 1.20836 17.3334 1.6667V8.33336C17.3334 8.7917 17.1702 9.18406 16.8438 9.51045C16.5174 9.83684 16.125 10 15.6667 10H2.33335ZM2.33335 8.33336H15.6667V1.6667H13.1667V5.00003H11.5V1.6667H9.83335V5.00003H8.16669V1.6667H6.50002V5.00003H4.83335V1.6667H2.33335V8.33336Z" fill="#707070"/>
                </svg>
                <p class="info-text">
                    <strong class="info-number">{{ itemDetail.sqft }}</strong>
                     sqft
                </p>
            </div>
            <div class="info-box">
                <svg class="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.875 16.8334C10.5556 17.1528 10.1597 17.3125 9.68752 17.3125C9.2153 17.3125 8.81947 17.1528 8.50002 16.8334L1.16669 9.50004C1.01391 9.34726 0.892381 9.1667 0.802104 8.95837C0.711826 8.75004 0.666687 8.52781 0.666687 8.2917V2.33337C0.666687 1.87504 0.829881 1.48267 1.15627 1.15629C1.48266 0.829897 1.87502 0.666702 2.33335 0.666702H8.29169C8.5278 0.666702 8.75002 0.711841 8.95835 0.802119C9.16669 0.892397 9.34724 1.01392 9.50002 1.1667L16.8334 8.52087C17.1528 8.84031 17.3125 9.23268 17.3125 9.69795C17.3125 10.1632 17.1528 10.5556 16.8334 10.875L10.875 16.8334ZM9.68752 15.6667L15.6459 9.70837L8.29169 2.33337H2.33335V8.2917L9.68752 15.6667ZM4.41669 5.6667C4.76391 5.6667 5.05905 5.54517 5.3021 5.30212C5.54516 5.05906 5.66669 4.76392 5.66669 4.4167C5.66669 4.06948 5.54516 3.77434 5.3021 3.53129C5.05905 3.28823 4.76391 3.1667 4.41669 3.1667C4.06946 3.1667 3.77433 3.28823 3.53127 3.53129C3.28821 3.77434 3.16669 4.06948 3.16669 4.4167C3.16669 4.76392 3.28821 5.05906 3.53127 5.30212C3.77433 5.54517 4.06946 5.6667 4.41669 5.6667Z" fill="#707070"/>
                </svg>
                <p class="info-text">
                    <strong class="info-number">{{ pricePerSqft }}</strong>
                     /sqft
                </p>
            </div>
            <div class="info-box">
                <svg class="icon" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.33335 10C1.87502 10 1.48266 9.83684 1.15627 9.51045C0.829881 9.18406 0.666687 8.7917 0.666687 8.33336V1.6667C0.666687 1.20836 0.829881 0.816003 1.15627 0.489614C1.48266 0.163225 1.87502 3.05176e-05 2.33335 3.05176e-05H15.6667C16.125 3.05176e-05 16.5174 0.163225 16.8438 0.489614C17.1702 0.816003 17.3334 1.20836 17.3334 1.6667V8.33336C17.3334 8.7917 17.1702 9.18406 16.8438 9.51045C16.5174 9.83684 16.125 10 15.6667 10H2.33335ZM2.33335 8.33336H15.6667V1.6667H13.1667V5.00003H11.5V1.6667H9.83335V5.00003H8.16669V1.6667H6.50002V5.00003H4.83335V1.6667H2.33335V8.33336Z" fill="#707070"/>
                </svg>
                <p class="info-text">
                    <strong class="info-number">{{ itemDetail.lotSize }}</strong>
                     sqft(lot)
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ItemDetail } from '@/types/ItemDetail';

const props = defineProps<{
    itemDetail: ItemDetail,
}>();

const price = props.itemDetail.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
const pricePerSqft = (props.itemDetail.price / props.itemDetail.sqft).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
</script>

<style>
.price {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.1;
    color: #000000;
    margin: 0;
    padding-right: 10px;
    letter-spacing: 1px;
}

.street-name {
    font-weight: 400;
    font-size: 24px;
    margin-top: 10px;
    line-height: 1.3;
    color: #000000;
    margin-bottom: 12px;
    margin: .67em 0;
}

.city-state-zip {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: #929292;
    letter-spacing: 1px;
}

.info-box-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.info-box {
    min-width: 68px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    margin: .5em .25rem .5rem .25rem;
}

.icon {
    margin: 10px;
}

.info-text {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin: 12px 0 0;
    color: #575757;
    text-align: center;
}

.info-number {
    display: block;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    display: block;
    margin-bottom: 10px;
    line-height: 14px;
    text-align: center;
}
</style>