<template>
  <div class="container">
    <div class="image-blocker">
      <div class="image-container">
        <img :src="thumbnailAbsolutePath" :alt="item.address.streetName" class="thumbnail">
      </div>
    </div>
    <div class="address-container">
        <h4 class="street-name">{{ item.address.streetName }}</h4>
        <h4 class="city">{{ item.address.city }}</h4>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BlobConstants } from '@/blobs/BlobConstants';
import { Item } from '@/types/Item';

const props = defineProps<{
  item: Item
}>();

const thumbnailAbsolutePath = BlobConstants.BLOB_STORAGE_HOST + props.item.thumbnail;
</script>

<style scoped>
.container {
  position: relative;
  height: 330px;
}
.image-blocker {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-container {
  width: 100%;
  height: 100%;
  transition: all ease-in-out 200ms;
}
.image-container:hover {
  transform: scale(1.1,1.1);
  transition: all ease-in-out 200ms;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.address-container {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  bottom: 0;
  z-index: 3;
  padding: 15px;
  text-shadow: 2px 2px 4px #000000;
}

.street-name {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.city {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0px;
}
</style>