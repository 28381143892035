<template>
  <div>
    <div class="title-container">
        <h3 class="title">{{ title }}</h3>
    </div>
    <div class="item-list-container">
      <div v-for="item in itemList" :key="item.id" class="item-container">
        <PropertyItem :item="item" @click="onClick(item)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Item } from '@/types/Item';
import PropertyItem from './PropertyItem.vue';
import router from '@/router/index';

defineProps<{
    title: string,
    itemList: Item[],
}>();
const onClick = (item: Item) => {
	router.push({ name: 'details', params: { 'id': item.id } });
};
</script>

<style scoped>
.title-container {
    display: block;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
}
.title {
    font-size: 32.73px;
    letter-spacing: 8px;
    font-weight: 800;
    color: #fff;
    text-align: center;
}
.item-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: flex-start;
  max-width: 1450px;
  margin:0 auto;
  gap: 30px;
}
.item-container {
  width: 400px;
}
</style>