<template>
  <div class="home">
    <SelfIntro class="self-intro"/>
    <div class="gallery-container">
      <PropertyGallery v-if="listings && listings.length > 0" :itemList="listings" title="Active Listings"/>
      <PropertyGallery v-if="sold && sold.length > 0" :itemList="sold" title="Recently Sold"/>
      <PropertyGallery v-if="bought && bought.length > 0" :itemList="bought" title="Recently Bought"/>
    </div>
  </div>

</template>

<script lang="ts" setup>
import SelfIntro from '@/components/SelfIntro.vue';
import PropertyGallery from '@/components/PropertyGallery.vue';
import { Item } from '@/types/Item';
import { ItemStatus } from '@/types/ItemStatus';
import { ref, onMounted } from 'vue';
import { BlobStorageReader } from '@/blobs/BlobStorageReader';

const listings = ref([] as Item[]);
const sold = ref([] as Item[]);
const bought = ref([] as Item[]);

onMounted(async () => {
	const itemList = (await BlobStorageReader.Instance.getGalleryData()).filter(a => !a.id.startsWith('test'));
	listings.value = itemList.filter(a => a.status == ItemStatus.Listing);
	sold.value = itemList.filter(a => a.status == ItemStatus.Sold);
	bought.value = itemList.filter(a => a.status == ItemStatus.Bought);
});
</script>

<style scoped>
.self-intro {
  display: block;
  padding-top: 3.188vw;
  margin-left: 4.875vw;
}
.gallery-container {
  background-color: black;
  margin-top: 40px;
  padding: 20px 30px 60px 30px;
}
</style>
