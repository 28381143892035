<template>
    <div class="root">
        <div class="container">
            <h3 class="name">Ellen Zhang</h3>
            <i class="fa fa-map-marker"></i>
            <span> WeLakeside | 800 Bellevue Way NE, Suite 500</span>
            <br>
            <span>Bellevue, WA 98004</span>
            <br>
            <i class="fa fa-phone"></i>
            <a href="tel:206-698-8755"> 206.698.8755</a>
            <br>
            <i class="fa fa-envelope"></i>
            <a href="mailto:ezhangrealestate@gmail.com"> ezhangrealestate@gmail.com</a>
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>

.root {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
    line-height: 30px;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.9;
    letter-spacing: 1px;
    color: #000;
}

.name {
    font-size: 34px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.1;
}

a {
    color: #333;
    text-decoration: none;
}

a:focus, a:hover {
    text-decoration: underline;
}
</style>