import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ContactView from '../views/ContactView.vue';
import PropertyDetailView from '../views/PropertyDetailView.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
	},
	{
		path: '/contact',
		name: 'contact',
		component: ContactView,
	},
	{
		path: '/details/:id',
		name: 'details',
		component: PropertyDetailView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
