<template>
  <div class="root-container">
    <div class="root">
      <div class="upper-container">
        <ItemHdImage v-if="itemDetail" class="images" :imageList="itemDetail.imageUrls"/>
        <PropertyInfo v-if="itemDetail" class="info" :itemDetail="itemDetail"/>
      </div>
      <div class="description-container">
        <h3 class="description-title">Property Description</h3>
        <p class="description">{{ itemDetail?.propertyDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BlobStorageReader } from '@/blobs/BlobStorageReader';
import ItemHdImage from '@/components/ItemHdImage.vue';
import PropertyInfo from '@/components/PropertyInfo.vue';
import { ItemDetail } from '@/types/ItemDetail';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const id = route.params.id;
const itemDetail = ref<ItemDetail>();
onMounted(async () => {
	itemDetail.value = await BlobStorageReader.Instance.getPropertyDetailData(id as string);
});

</script>

<style scoped>

.root-container {
  display: flex;
  justify-content: center;
}
.root {
  max-width: 1600px;
}

.upper-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 50px;
  border-bottom: 1px solid #e9e9e9;
  margin: 40px 0;
}
.images {
  max-width: 1000px;
  flex: 60%
}
.info {
  margin-left: 40px;
  flex: 30%
}

@media (max-width:800px) {
  .images {
    flex: 100%
  }
  .info {
    margin-left: 0px;
    flex: 100%
  }
}

.description-container {
  margin: 40px 40px 80px 40px;
}
.description-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #000000;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: #000000;
  margin: 0 0 10px;
  letter-spacing: 1px;
}
</style>
